import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class CustomDateAdapterComponent extends NativeDateAdapter {
  override getFirstDayOfWeek(): number {
    return 1;
  }


  override parse(value: string): Date | null {
    const dateComponents = value.split(/[.-/]/).map(Number);

    if (dateComponents.length !== 3) {
      return null;
    }

    const [day, month, year] = dateComponents;

    // Check if the day, month, and year are numbers
    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return null;
    }

    // Check for invalid day (1-31) and month (1-12)
    if (day < 1 || day > 31 || month < 1 || month > 12) {
      return null;
    }

    // Check for months with less than 31 days
    if (month === 2 && day > 29) {
      return null;
    }
    if ((month === 4 || month === 6 || month === 9 || month === 11) && day > 30) {
      return null;
    }

    // Check for leap years
    if (month === 2 && day === 29 && !(year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0))) {
      return null;
    }

    // Assuming 'year', 'month', and 'day' are provided variables
    let fullYear = year; // Assuming year is a provided variable

    // If the year is represented as a two-digit number
    if (year.toString().length === 2) {
      // Extracting the last two digits of the current year
      const currentYearFirstTwoDigits = new Date().getFullYear().toString().slice(0, 2);

      // Converting two-digit year to full four-digit year
      fullYear = parseInt(currentYearFirstTwoDigits + year.toString(), 10);
    }

    return new Date(fullYear, month - 1, day);
  }
}
